import React from "react";
import TextLoop from "react-text-loop";
import Particles from "react-particles-js";
import { Container } from "react-grid-system";

function Intro() {
    return (
        /*<section className="section section-hero section-hero-1 overlay-image" style={{ backgroundImage: `url(/assets/images/hero/1-1.jpg)` }}>*/
        <section className="section section-hero section-hero-1 overlay-image">
            <Particles
                params={{
                    particles: {
                        color: {
                            value: '#000000'
                        },
                        number: {
                            value: 150,
                            density: {
                                enable: true,
                            },
                        },
                        size: {
                            value: 5,
                            random: true,
                        },
                        move: {
                            direction: "bottom",
                            out_mode: "out",
                        },
                        line_linked: {
                            enable: false,
                        },
                        opacity: {
                            value: 0.3
                        }
                    },
                    interactivity: {
                        events: {
                            onhover: {
                                enable: true,
                                mode: "bubble"
                            },
                            onclick: {
                                enable: true,
                                mode: "repulse"
                            }
                        },
                        modes: {
                            bubble: {
                                distance: 250,
                                opacity: 0.1,
                                duration: 2,
                                size: 3,
                            },
                            repulse: {
                                distance: 400,
                                duration: 4
                            }
                        }
                    }
                }}
            />
            <div className="display-center">
                <Container>
                    <div className="el-heading">
                        <p className="el-icon">
                            <span className="el-icon-title">I'm Umer Farooq.</span>
                        </p>
                        <h1>
                            Page will be Live Soon!, {" "}
                            <h3>Stay Connected!</h3>

                            <h4>
                            <TextLoop>
                                <span>Mission</span>
                                <span>Vision</span>
                                <span>What I do</span>
                                <span>Events</span>
                                <span>Blogs</span>
                                <span>Technology Discussions</span>
                                <span>Career Counseling</span>
                                <span>Job Hunting Tips</span>
                                <span>Personal Branding</span>
                                <span>And More!!!</span>
                                <span>Follow Me at:</span>
                                <span>Social Media</span>
                                <span>LinkedIn</span>
                                <span>Facebook</span>
                            </TextLoop>
                            <br />
                            </h4>
                        </h1>
                        {/*<button className="button button-lg button-primary">
                            <span className="wave"></span>
                            <span className="text">My Portfolio</span>
                        </button>
                        <button className="button button-lg">
                            <span className="text">Read More</span>
                        </button>*/}
                    </div>
                </Container>
            </div>
        </section>
    );
}

export default Intro;
